// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-article-template-jsx": () => import("./../src/templates/article-template.jsx" /* webpackChunkName: "component---src-templates-article-template-jsx" */),
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-page-2-jsx": () => import("./../src/pages/page-2.jsx" /* webpackChunkName: "component---src-pages-page-2-jsx" */),
  "component---src-pages-politica-de-privacidad-jsx": () => import("./../src/pages/politica-de-privacidad.jsx" /* webpackChunkName: "component---src-pages-politica-de-privacidad-jsx" */),
  "component---src-pages-seminario-nacional-de-mujeres-2020-registro-jsx": () => import("./../src/pages/seminario-nacional-de-mujeres-2020/registro.jsx" /* webpackChunkName: "component---src-pages-seminario-nacional-de-mujeres-2020-registro-jsx" */)
}

