import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';

const initialState = { openMenu: false };

const reducer = (state, { type }) => {
  switch (type) {
    case 'OPEN_MENU':
      return { openMenu: true };
    case 'CLOSE_MENU':
      return { openMenu: false };
    default:
      return state;
  }
};

const DrawerContext = createContext(initialState);

const DrawerProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <DrawerContext.Provider value={{ state, dispatch }}>
      {children}
    </DrawerContext.Provider>
  );
};

DrawerProvider.propTypes = { children: PropTypes.element };

export { DrawerContext, DrawerProvider };
